import 'magnific-popup'
import $ from 'jquery'

// options at: https://dimsemenov.com/plugins/magnific-popup/

class Modal {
  constructor() {
    if (
      document.location.hash !== ''
      && $(document.location.hash).length
      && $(document.location.hash).hasClass('block--type--modal')
    ) {
      $.magnificPopup.open({
        items: {
          src: document.location.hash,
          type: 'inline',
        },
        overflowY: 'scroll'
      })
    }

    

    $('a').each(function () {
      const href = $(this).attr('href')

      this.addEventListener('click', function (e) {
        function getSchemeAndDomain(url) {
          try {
            // Create a new URL object
            const parsedUrl = new URL(url);
        
            // Extract the scheme (protocol) and domain (hostname)
            const scheme = parsedUrl.protocol;
            const domain = parsedUrl.hostname;
        
            // Return the scheme and domain
            return `${scheme}//${domain}`;
          } catch (error) {
            console.error('Invalid URL:', error);
            return null;
          }
        }

        if (href) {
          const externalLinkEl = document.querySelector('.external-link')
          if (externalLinkEl) {
            externalLinkEl.innerText = ' ' + getSchemeAndDomain(href)
          }
        }
      })


      if (
        typeof href !== 'undefined'
        && href.startsWith('#')
        && href.length > 1
        && $(href).hasClass('block--type--modal')
      ) {
        console.log(href)

        $(this).magnificPopup({
          items: {
            src: href,
            type: 'inline'
          },
          //type: 'inline',
          overflowY: 'scroll'
        })
      }

      if (
        typeof href !== 'undefined'
        && href.startsWith('http')
        && !href.includes(window.location.host)
        && !href.includes('https://recruiting.ultipro.com')
        && !href.includes('ir.ionis')
        && !href.includes('ionistrials')
        && $('#popleave').length
      ) {
   
        $(this).magnificPopup({
          items: {
            src: '#popleave',
            type: 'inline'
          },
          callbacks: {
            open: function(e) {
              const closeBtn = document.querySelector('.mfp-close')
              const closeBtnImage = document.createElement('img')
              closeBtnImage.src = '/themes/custom/envivent/assets/images/global/close-btn.svg'
              closeBtnImage.alt = 'Close'
              closeBtn.innerHTML = ''
              closeBtn.classList.add('close-btn')
              closeBtn.appendChild(closeBtnImage)

              const okBtn = document.querySelector('#popleave .ok')
              okBtn.setAttribute('href', href)
              okBtn.setAttribute('target', '_blank')

              closeBtn.addEventListener('click', function (e) {
                e.preventDefault
                e.stopPropagation
                $.magnificPopup.close()
                return false
              })
              
              
              $('#popleave .ok').on('click', function (e) {
                $.magnificPopup.close()
              })

              $('#popleave .cancel').on('click', function (e) {
                e.preventDefault
                e.stopPropagation
                $.magnificPopup.close()
                return false
              })
            }
          }
        })
      }
    })
  }
}

export default Modal
