class MediaCenter {
  constructor(el) {
    // Initialize expand buttons for menus
    console.log('Initializing')
    const menuItems = el.querySelectorAll('li')

    menuItems.forEach(item => {
      const hasChildren = item.querySelectorAll('ul').length
      if (hasChildren) {
        const toggle = item.querySelector('.toggle')
        item.classList.add('has-children')
        item.querySelector('a')
          .setAttribute('aria-haspopup', 'true')
        item.querySelector('a')
          .setAttribute('aria-expanded', 'true')

        item.addEventListener('click', (e) => {
          
          console.dir(e.offsetX, window.innerWidth - 80)
          const toggleClicked = e.offsetX < 0
          
          if (toggleClicked) {
            e.preventDefault()
            if (item.classList.contains('opened')) {
              item.classList.remove('opened')
            } else {
              //menuItems.forEach(x => x.classList.remove('opened'))
              item.classList.add('opened')
            }
          }
        })
        toggle.addEventListener('click', (e) => {
            e.preventDefault()
            if (item.classList.contains('opened')) {
              item.classList.remove('opened')
            } else {
              //menuItems.forEach(x => x.classList.remove('opened'))
              item.classList.add('opened')
            }
        })
      }
    })

    // make parents active
    const active = el.querySelector('.opened')
    if (active) {
      const parentPage = active.parentNode.parentNode.parentNode

      if (parentPage.classList.contains('has-children')) {
        parentPage.querySelector('a').classList.add('opened')
      }
    }
  }
}

export default MediaCenter
