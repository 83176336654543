class Polyfills {
  constructor() {
    // From https://github.com/kevlatus/polyfill-array-includes/blob/master/array-includes.js
    if (!Array.prototype.includes) {
      Object.defineProperty(Array.prototype, 'includes', {
        value: function (searchElement, fromIndex) {

          // 1. Let O be ? ToObject(this value).
          if (this == null) {
            throw new TypeError('"this" is null or not defined')
          }

          var o = Object(this)

          // 2. Let len be ? ToLength(? Get(O, "length")).
          var len = o.length >>> 0

          // 3. If len is 0, return false.
          if (len === 0) {
            return false
          }

          // 4. Let n be ? ToInteger(fromIndex).
          //    (If fromIndex is undefined, this step produces the value 0.)
          var n = fromIndex | 0

          // 5. If n ≥ 0, then
          //  a. Let k be n.
          // 6. Else n < 0,
          //  a. Let k be len + n.
          //  b. If k < 0, let k be 0.
          var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0)

          function sameValueZero(x, y) {
            return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y))
          }

          // 7. Repeat, while k < len
          while (k < len) {
            // a. Let elementK be the result of ? Get(O, ! ToString(k)).
            // b. If SameValueZero(searchElement, elementK) is true, return true.
            // c. Increase k by 1.
            if (sameValueZero(o[k], searchElement)) {
              return true
            }
            k++
          }

          // 8. Return false
          return false
        }
      })
    }

    if (window.NodeList && !NodeList.prototype.forEach) {
      NodeList.prototype.forEach = Array.prototype.forEach
    }

    function CustomEvent ( event, params ) {
      params = params || { bubbles: false, cancelable: false, detail: null }
      var evt = document.createEvent( 'CustomEvent' )
      evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail )
      return evt
    }

    if ( typeof window.CustomEvent !== 'function' ) {

      window.CustomEvent = CustomEvent
    }
  }
}

new Polyfills()